define("discourse/plugins/waze-header/discourse/components/waze-app-launcher", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#unless this.site.mobileView}}
    <wz-app-launcher
      class="icon wz-app-launcher"
      title={{I18n "waze.app_launcher.title"}}
    >
      <wz-app-launcher-item
        icon="livemap"
        {{on "click" (fn this.windowOpenTargetBlank "/live-map")}}
      >
        {{I18n "waze.app_launcher.livemap"}}
      </wz-app-launcher-item>
      <wz-app-launcher-item
        icon="editor"
        {{on "click" (fn this.windowOpenTargetBlank "/editor")}}
      >
        {{I18n "waze.app_launcher.map_editor"}}
      </wz-app-launcher-item>
      <wz-app-launcher-item
        icon="forums"
        {{on "click" (fn this.windowOpenTargetBlank "/forum")}}
      >
        {{I18n "waze.app_launcher.forums"}}
      </wz-app-launcher-item>
      <wz-app-launcher-item
        icon="wazeopedia"
        {{on "click" (fn this.windowOpenTargetBlank "/wiki")}}
      >
        {{I18n "waze.app_launcher.wazeopedia"}}
      </wz-app-launcher-item>
      <wz-app-launcher-item
        icon="partners"
        {{on "click" (fn this.windowOpenTargetBlank "/partnerhub")}}
      >
        {{I18n "waze.app_launcher.partner_hub"}}
      </wz-app-launcher-item>
    </wz-app-launcher>
  {{/unless}}
  */
  {
    "id": "e7qmkJKI",
    "block": "[[[41,[51,[30,0,[\"site\",\"mobileView\"]]],[[[1,\"  \"],[10,\"wz-app-launcher\"],[14,0,\"icon wz-app-launcher\"],[15,\"title\",[28,[37,2],[\"waze.app_launcher.title\"],null]],[12],[1,\"\\n    \"],[11,\"wz-app-launcher-item\"],[24,\"icon\",\"livemap\"],[4,[38,4],[\"click\",[28,[37,5],[[30,0,[\"windowOpenTargetBlank\"]],\"/live-map\"],null]],null],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"waze.app_launcher.livemap\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[11,\"wz-app-launcher-item\"],[24,\"icon\",\"editor\"],[4,[38,4],[\"click\",[28,[37,5],[[30,0,[\"windowOpenTargetBlank\"]],\"/editor\"],null]],null],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"waze.app_launcher.map_editor\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[11,\"wz-app-launcher-item\"],[24,\"icon\",\"forums\"],[4,[38,4],[\"click\",[28,[37,5],[[30,0,[\"windowOpenTargetBlank\"]],\"/forum\"],null]],null],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"waze.app_launcher.forums\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[11,\"wz-app-launcher-item\"],[24,\"icon\",\"wazeopedia\"],[4,[38,4],[\"click\",[28,[37,5],[[30,0,[\"windowOpenTargetBlank\"]],\"/wiki\"],null]],null],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"waze.app_launcher.wazeopedia\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[11,\"wz-app-launcher-item\"],[24,\"icon\",\"partners\"],[4,[38,4],[\"click\",[28,[37,5],[[30,0,[\"windowOpenTargetBlank\"]],\"/partnerhub\"],null]],null],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"waze.app_launcher.partner_hub\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"unless\",\"wz-app-launcher\",\"I18n\",\"wz-app-launcher-item\",\"on\",\"fn\"]]",
    "moduleName": "discourse/plugins/waze-header/discourse/components/waze-app-launcher.hbs",
    "isStrictMode": false
  });
  // @ts-expect-error no types

  // @ts-expect-error no types

  // @ts-expect-error no types

  class WazeAppLauncher extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    windowOpenTargetBlank(path) {
      window.open(path, "_blank");
    }
    static #_2 = (() => dt7948.n(this.prototype, "windowOpenTargetBlank", [_object.action]))();
  }
  _exports.default = WazeAppLauncher;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, WazeAppLauncher);
});